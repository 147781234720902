import { routeToUrl } from "@app-routes";
import { InverterManufacturer, useLeadCreateUpdateMutation } from "@gql-schema";
import { ErrorService } from "@shared/services/error-service";
import { CheckboxInput } from "@uxf/form/checkbox-input";
import { Form } from "@uxf/form/form";
import { NumberInput } from "@uxf/form/number-input";
import { Select } from "@uxf/form/select";
import { TextInput } from "@uxf/form/text-input";
import { Button } from "@uxf/ui/button";
import { TextLink } from "@uxf/ui/text-link";
import Link from "next/link";
import { useRouter } from "next/router";
import { SubmitHandler, useForm } from "react-hook-form";

interface HomepageFormData {
    email: string;
    inverterManufacturer: InverterManufacturer;
    inverterType: string;
    batteryCapacity: number | undefined;
    powerPlantPerformance: number;
    notHasStorage: boolean;
}

interface SelectOption {
    id: string;
    label: string;
}
const MANUFACTURER_OPTIONS: SelectOption[] = [
    { id: "SOLAX", label: "Solax" },
    { id: "VICTRON", label: "Victron" },
    { id: "GROWATT", label: "Growatt" },
    { id: "GOOD_WE", label: "GoodWe" },
    { id: "OTHER", label: "Jiný výrobce" },
];

export function HomepageForm() {
    const { push } = useRouter();
    const formApi = useForm<HomepageFormData>();
    const [leadCreateUpdate] = useLeadCreateUpdateMutation();

    const onSubmit: SubmitHandler<HomepageFormData> = async (values) => {
        try {
            const lead = await leadCreateUpdate({
                variables: {
                    input: {
                        powerPlantPerformance: values.powerPlantPerformance,
                        batteryCapacity: values.batteryCapacity,
                        inverterManufacturer: values.inverterManufacturer,
                        inverterType: values.inverterType,
                        email: values.email,
                    },
                },
            });

            if (lead.data) {
                await push(routeToUrl("main-zone/thank-you-page"));
            }
        } catch (e) {
            ErrorService.handleError(e);
        }
    };

    return (
        <Form formApi={formApi} id="homepage-form" onSubmit={onSubmit}>
            <div className="space-y-10">
                <TextInput
                    control={formApi.control}
                    label="Jaký je Váš e-mail?"
                    name="email"
                    type="email"
                    placeholder="např.: jan.novak@email.cz"
                    isRequired
                />
                <Select
                    control={formApi.control}
                    label="Jaký je výrobce Vašeho střídače?"
                    name="inverterManufacturer"
                    placeholder="Vyberte výrobce střídače"
                    isRequired
                    options={MANUFACTURER_OPTIONS}
                />
                <TextInput
                    control={formApi.control}
                    label="Jaký je typ Vašeho střídače?"
                    name="inverterType"
                    placeholder="např.: Solax Hybrid G3 popř. nevím"
                    isRequired
                />
                <div className="space-y-2">
                    <NumberInput
                        control={formApi.control}
                        decimalPlaces={2}
                        isDisabled={formApi.watch("notHasStorage")}
                        isRequired={!formApi.watch("notHasStorage")}
                        label="Jakou kapacitu má vaše baterie?"
                        name="batteryCapacity"
                        placeholder="např.: 10"
                        rightAddon="kWh"
                    />
                    <CheckboxInput control={formApi.control} name="notHasStorage" label="Nemám bateriové uložiště" />
                </div>
                <NumberInput
                    control={formApi.control}
                    label="Jaký je výkon Vaší FVE?"
                    name="powerPlantPerformance"
                    placeholder="např.: 10"
                    rightAddon="kWp"
                    decimalPlaces={2}
                />
                <p className="text-body2">
                    <span className="text-error font-bold">*</span> povinné pole
                </p>
                <Button isFullWidth type="submit" loading={formApi.formState.isSubmitting}>
                    Odeslat dotazník
                </Button>
            </div>
            <div className="text-small text-center pt-6">
                <p className="font-medium">
                    Odesláním dotazníku souhlasíte s&nbsp;
                    <Link href={routeToUrl("main-zone/terms-and-conditions")} target="_blank" passHref legacyBehavior>
                        <TextLink>obchodními podmínkami</TextLink>
                    </Link>{" "}
                    a&nbsp;berete na&nbsp;vědomí informace o&nbsp;
                    <TextLink href="https://www.centropol.cz/gdpr/" target="_blank">
                        zpracování osobních údajů
                    </TextLink>
                </p>
            </div>
        </Form>
    );
}
